
.root {
    display: flex;
    justify-content: space-between;
}

.content {
    max-width: calc(var(--base-unit) * 80);
    margin-right: var(--spacing-7);

    /* Necessary for long labels, see: https://css-tricks.com/flexbox-truncated-text/ */
    min-width: 0;
}

.helpText {
    color: var(--text-secondary-color);
}

.toggle {
    align-items: center;
    background: var(--bg-toggle-color);
    border-radius: var(--spacing-5);
    box-shadow: 0 0 0 1px var(--border-toggle-color);
    color: var(--text-inverted-color);
    display: flex;
    height: var(--spacing-6);
    padding: 0 var(--base-unit);
    position: relative;
    transition: var(--background-transition), var(--box-shadow-transition);
    width: 44px;

    /*  For Windows High Contrast Mode */
    &:focus { outline: 1px solid transparent; }

    .knob { background-color: currentColor; }

    &[aria-pressed='false'] {
        &:focus { box-shadow: 0 0 0 2px var(--border-interactive-color); }

        .knob { right: 22px; }
    }

    &[aria-pressed='true'] {
        background: var(--bg-toggle-activated-color);
        box-shadow: none;
        line-height: 0;

        &:focus { box-shadow: 0 0 0 2px var(--border-dark-color); }
    }

    &:disabled {
        background-color: var(--bg-toggle-color);
        color: var(--brand-coconut-50);
        box-shadow: none;
        cursor: not-allowed;
    }

    .toggleGroup {
        display: flex;
        align-items: center;
        width: 44px;
    }
}

.knob {
    border-radius: 50%;
    height: 18px;
    pointer-events: none;
    position: absolute;
    transition:
        background-color var(--duration-base) ease-in-out,
        right var(--duration-base) ease-in-out;
    right: 3px;
    width: 18px;
}

/*  Temporary until tick small icon is added to wink-icons */
.tickIcon {
    fill: currentColor;
    height: var(--spacing-4);
    width: var(--spacing-4);
}

/*  Style when label is hidden */
.hideLabel {
    /*  Remove alignment on Toggle Buttons with no label */
    justify-content: flex-start;
    .content {
        margin-right: 0;
    }
}

:global(.mcds-redesign-molecules) {
    .root {
        align-items: flex-start;
        flex-direction: row;
        gap: var(--space-component-gap-medium);
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--space-component-gap-x-small);
        margin-right: 0;
    }

    .toggle {
        height: var(--space-row-gap-medium);
        padding: var(--space-component-stack-padding-x-small, 2px) var(--space-component-inline-padding-xx-small, 2px);
        align-self: stretch;
        background: var(--color-input-background-secondary);
        border-radius: var(--radius-full);
        box-shadow: 0 0 0 var(--space-component-gap-x-small) transparent;
        color: var(--text-inverted-color);
        width: calc(var(--base-unit) * 9);

        /*  For Windows High Contrast Mode */
        &:focus-visible { outline: 1px solid var(--color-icon-inverse); }

        .knob { background-color: var(--color-icon-inverse); }

        &[aria-pressed='false'] {

            &:focus,
            &:hover {
                background: var(--color-input-background-secondary-hover);
                box-shadow: none;
            }

            &:focus-visible {
                box-shadow: 0 0 0 calc(var(--base-unit) - 1px) var(--color-ui-primary);
                background: var(--color-input-background-secondary-hover);
            }

            .knob { right: calc(var(--base-unit) * 4.5); }

            &:disabled {
                background-color: var(--color-input-background-disabled);
                color: var(--color-icon-disabled);
                box-shadow: none;
                cursor: not-allowed;

                .knob {
                    background-color: var(--color-icon-disabled);
                    filter: none;
                }
            }
        }

        &[aria-pressed='true'] {
            background: var(--color-input-background-primary-selected);
            box-shadow: none;
            line-height: 0;

            &:focus,
            &:hover { background: var(--color-action-standard-hover); }

            &:focus-visible {
                box-shadow: 0 0 0 calc(var(--base-unit) - 1px) var(--color-ui-primary);
                background: var(--color-action-standard-hover);
            }

            &:disabled {
                background-color: var(--color-input-background-primary-selected);
                color: var(--color-icon-inverse);
                box-shadow: none;
                cursor: not-allowed;
                opacity: var(--opacity-disabled);
                .knob {
                    filter: none;
                }
            }
        }

    }

    .toggleGroup {
        padding: var(--space-component-stack-padding-x-small) 0;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .knob {
        border-radius: var(--radius-full);
        height: var(--space-medium);
        width: var(--space-medium);
        right: 2px;
        filter: drop-shadow(0 2px 2px var(--color-shadow));
    }

    .label {
        display: flex;
        padding: var(--space-component-stack-padding-x-small) 0;
        align-items: flex-start;
        gap: var(--space-component-gap-medium);

        color: var(--color-text-primary);
        font-family: var(--font-family-component);
        font-size: var(--font-size-component-small);
        font-style: normal;
        font-weight: var(--font-weight-component);
        line-height: var(--space-row-gap-medium);
    }

    .helpText {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--space-component-gap-medium);
        color: var(--color-text-secondary);
        font-family: var(--font-family-body);
        font-size: var(--font-size-body-4);
        font-style: normal;
        font-weight: var(--font-weight-component);
        line-height: var(--line-height-body);
    }

    .right {
        flex-direction: row-reverse;
    }
}
