/*------------------------------------*\
    #Marketing Card Root
\*------------------------------------*/

.root {
    &:hover {
        .targetLink {
            text-decoration: underline;
        }
    }

    &:focus-within {
        transition: var(--box-shadow-transition);
        box-shadow: 0 0 0 4px var(--bg-lvl-1-color), 0 0 0 6px var(--border-interactive-color);

        .targetLink {
            outline: none;
            text-decoration: underline;
        }
    }
}

/*  TODO Add responsive layout */
.content {
    padding: var(--spacing-4) 0;
}

.eyebrow {
    text-transform: uppercase;
    font-size: var(--font-size-small);
}

.targetLink {
    font: var(--font-heading-5);
    font-weight: var(--font-heading-weight);
    margin-top: var(--spacing-1);
    margin-bottom: var(--spacing-2);
    text-decoration: none;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

:global(.mcds-redesign-organisms) {
    .root {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--space-component-gap-large);

        &.isHorizondal {
            display: inline-flex;
            flex-direction: row;
        }

        &:hover,
        &:focus-within {
            cursor: pointer;

            .targetLink {
                text-decoration: underline;
                text-decoration-style: solid;
                text-decoration-skip-ink: auto;
                text-decoration-thickness: 7%;
                text-underline-offset: 12%;
                text-underline-position: from-font;
            }
        }

        &:focus-within {
            border-radius: var(--radius-small);
            box-shadow: none;
            outline-offset: 1px;
            outline: 2px solid var(--color-ui-primary);
        }

        .imgWrapper {
            border-radius: var(--radius-small) !important;
            width: 100%;
            min-width: 198px;
            padding: 0;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--space-component-gap-small);
            align-self: stretch;
            padding: 0;
        }

        .eyebrow {
            color: var(--color-text-tertiary);
            font-family: var(--font-family-component);
            font-size: var(--font-size-component-x-small);
            font-weight: var(--font-weight-component);
            line-height: var(--line-height-component);
            text-transform: none;
        }

        .targetLink {
            color: var(--color-text-primary);
            font-family: var(--font-family-component);
            font-size: var(--font-size-component-large);
            font-weight: var(--font-weight-component-bold);
            line-height: var(--line-height-component);
            letter-spacing: -0.2px;
            margin: 0;
        }

        .description {
            align-self: stretch;
            color: var(--color-text-secondary);
            font-family: var(--font-family-body);
            font-size: var(--font-size-body-3);
            font-weight: var(--font-weight-body);
            line-height: var(--line-height-body);
            max-width: 100%;
        }
    }
}
