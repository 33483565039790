/*------------------------------------*\
    #Action Card Root
\*------------------------------------*/

.root {
    display: grid;
    grid-template-columns: 152px 4fr;
    min-height: 152px;

    &.isVertical {
        grid-template-columns: 1fr;

        .imgWrapper {
            min-height: 200px;
            border-radius: var(--border-card-radius) var(--border-card-radius) 0 0;
        }
    }

    &:focus-within {
        /*  Overriding focus styles since this is using ClickArea. */
        .targetLink {
            outline: none;
        }
        .targetButton {
            outline: none;
        }
    }

    @media screen and (--mq-max-width-phone) {
        grid-template-columns: 1fr;

        .imgWrapper {
            min-height: 200px;
        }
    }
}
.content {
    padding: var(--spacing-6);
}

.description {
    margin-top: var(--spacing-3);
    margin-bottom: var(--spacing-6);
}

.imgWrapper {
    align-items: center;
    position: relative;
    border-radius: var(--border-card-radius) 0 0 var(--border-card-radius);
}

.targetLink {
    font-size: var(--font-size-small);
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

.targetButton {
    border: none;
    font-family: var(--typeface-base);
    color: var(--link-color);
    cursor: pointer;
    background: none;
    display: flex;
    padding: 0;
    font-size: var(--font-size-small);
    &:hover {
        color: var(--link-color-hover);
        text-decoration: underline;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

:global(.mcds-redesign-organisms) {
    .root {
        border-radius: var(--radius-small);
        border: 1px solid var(--color-container-border-primary);

        &.noMedia {
            grid-template-columns: 1fr;
        }

        .imgWrapper {
            border-radius: var(--radius-small) 0 0 var(--radius-small);
        }

        .content {
            gap: var(--space-component-stack-padding-large);
            padding: var(--space-container-padding-small);

            .contentBody {
                gap: var(--space-component-stack-padding-medium);

                .eyebrow {
                    color: var(--color-text-tertiary);
                    font-family: var(--font-family-component);
                    font-size: var(--font-size-component-x-small);
                    font-weight: var(--font-weight-component);
                    line-height: var(--line-height-component);
                    text-transform: none;
                }

                .contentInfo {
                    gap: var(--space-component-stack-padding-x-small);

                    .title {
                        color: var(--color-text-primary);
                        font-family: var(--font-family-body);
                        font-size: var(--font-size-body-2);
                        font-weight: var(--font-weight-body-bold);
                        line-height: var(--line-height-body);
                        letter-spacing: -0.16px;
                    }

                    .description {
                        color: var(--color-text-secondary);
                        font-family: var(--font-family-body);
                        font-size: var(--font-size-body-3);
                        font-weight: var(--font-weight-body);
                        line-height: var(--line-height-body);
                        margin: 0;
                    }
                }
            }

            .targetLink {
                color: var(--color-link-text);
                font-family: var(--font-family-component);
                font-size: var(--font-size-component-small);
                font-weight: var(--font-weight-body);
                line-height: var(--line-height-body);
                margin-top: 0;
            }
        }

        &.right {
            grid-template-columns: 4fr 152px;

            &.noMedia {
                grid-template-columns: 1fr;
            }

            .imgWrapper {
                order: 2;
                border-radius: 0 var(--radius-small) var(--radius-small) 0;
            }
            .content {
                order: 1;
            }
        }

        &.top,
        &.isVertical {
            grid-template-columns: 1fr;

            .imgWrapper {
                min-height: 200px;
                border-radius: var(--radius-small) var(--radius-small) 0 0;
            }
        }

        &:hover {
            border: 1px solid var(--color-container-border-tertiary);
            box-shadow: none;

            .targetLink {
                color: var(--color-link-text-hover);
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-decoration-skip-ink: auto;
                text-decoration-thickness: 7%;
                text-underline-offset: 12%;
                text-underline-position: from-font;
            }
        }

        &:focus-within {
            border: 1px solid var(--color-focus-indicator);
            box-shadow: none;
            outline-offset: -1px;
            outline: 2px solid var(--color-focus-indicator);
        }
    }
}
